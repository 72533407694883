import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function CountrySelect() {
  return (
    <Autocomplete
      id="country-select-demo"
      fullWidth
      className="countrySelect"
      options={countries}
      autoHighlight
      size="small"
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} 
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Pays"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries=[
	{code:"AD" ,label: "Andorre"},
	{code:"AE" ,label: "Émirats Arabes Unis"},
	{code:"AF" ,label: "Afghanistan"},
	{code:"AG" ,label: "Antigua-Et-Barbuda"},
	{code:"AI" ,label: "Anguilla"},
	{code:"AL" ,label: "Albanie"},
	{code:"AM" ,label: "Arménie"},
	{code:"AO" ,label: "Angola"},
	{code:"AP" ,label: "Région Asie/Pacifique"},
	{code:"AQ" ,label: "Antarctique"},
	{code:"AR" ,label: "Argentine"},
	{code:"AS" ,label: "Samoa Américaines"},
	{code:"AT" ,label: "Autriche"},
	{code:"AU" ,label: "Australie"},
	{code:"AW" ,label: "Aruba"},
	{code:"AX" ,label: "Îles Åland"},
	{code:"AZ" ,label: "Azerbaïdjan"},
	{code:"BA" ,label: "Bosnie-Herzégovine"},
	{code:"BB" ,label: "Barbad"},
	{code:"BD" ,label: "Bangladesh"},
	{code:"BE" ,label: "Belgique"},
	{code:"BF" ,label: "Burkina Faso"},
	{code:"BG" ,label: "Bulgarie"},
	{code:"BH" ,label: "Bahreïn"},
	{code:"BI" ,label: "Burundi"},
	{code:"BJ" ,label: "Bénin"},
	{code:"BL" ,label: "Saint-Barthélemy"},
	{code:"BM" ,label: "Bermudes"},
	{code:"BN" ,label: "Brunei Darussalam"},
	{code:"BO" ,label: "État Plurinational De Bolivie"},
	{code:"BQ" ,label: "Bonaire, Saint-Eustache Et Saba"},
	{code:"BR" ,label: "Brésil"},
	{code:"BS" ,label: "Bahamas"},
	{code:"BT" ,label: "Bhoutan"},
	{code:"BV" ,label: "Île Bouvet"},
	{code:"BW" ,label: "Botswana"},
	{code:"BY" ,label: "Biélorussie"},
	{code:"BZ" ,label: "Belize"},
	{code:"CA" ,label: "Canada"},
	{code:"CC" ,label: "Îles Cocos"},
	{code:"CD" ,label: "République Démocratique Du Congo"},
	{code:"CF" ,label: "République Centrafricaine"},
	{code:"CG" ,label: "Congo"},
	{code:"CH" ,label: "Suisse"},
	{code:"CI" ,label: "Côte D'Ivoire"},
	{code:"CK" ,label: "Îles Cook"},
	{code:"CL" ,label: "Chili"},
	{code:"CM" ,label: "Cameroun"},
	{code:"CN" ,label: "Chine"},
	{code:"CO" ,label: "Colombie"},
	{code:"CR" ,label: "Costa Rica"},
	{code:"CU" ,label: "Cuba"},
	{code:"CV" ,label: "Cap-Vert"},
	{code:"CW" ,label: "Curaçao"},
	{code:"CX" ,label: "Île Christmas"},
	{code:"CY" ,label: "Chypre"},
	{code:"CZ" ,label: "République Tchèque"},
	{code:"DE" ,label: "Allemagne"},
	{code:"DJ" ,label: "Djibouti"},
	{code:"DK" ,label: "Denmark"},
	{code:"DM" ,label: "Dominique"},
	{code:"DO" ,label: "République Dominicaine"},
	{code:"DZ" ,label: "Algérie"},
	{code:"EC" ,label: "Équateur"},
	{code:"EE" ,label: "Estonie"},
	{code:"EG" ,label: "Égypte"},
	{code:"EH" ,label: "Sahara Occidental"},
	{code:"ER" ,label: "Érythrée"},
	{code:"ES" ,label: "Espagne"},
	{code:"ET" ,label: "Éthiopie"},
	{code:"EU" ,label: "Europe"},
	{code:"FI" ,label: "Finlande"},
	{code:"FJ" ,label: "Fidji"},
	{code:"FK" ,label: "Îles Malouines"},
	{code:"FM" ,label: "États Fédérés De Micronésie"},
	{code:"FO" ,label: "Îles Féroé"},
	{code:"FR" ,label: "France"},
	{code:"GA" ,label: "Gabon"},
	{code:"GB" ,label: "Royaume-Uni"},
	{code:"GD" ,label: "Grenade"},
	{code:"GE" ,label: "Géorgie"},
	{code:"GF" ,label: "Guyane"},
	{code:"GG" ,label: "Guernesey"},
	{code:"GH" ,label: "Ghana"},
	{code:"GI" ,label: "Gibraltar"},
	{code:"GL" ,label: "Groenland"},
	{code:"GM" ,label: "Gambie"},
	{code:"GN" ,label: "Guinée"},
	{code:"GP" ,label: "Guadeloupe"},
	{code:"GQ" ,label: "Guinée Équatoriale"},
	{code:"GR" ,label: "Grèce"},
	{code:"GS" ,label: "Géorgie Du Sud-Et-Les Îles Sandwich Du Sud"},
	{code:"GT" ,label: "Guatemala"},
	{code:"GU" ,label: "Guam"},
	{code:"GW" ,label: "Guinée-Bissau"},
	{code:"GY" ,label: "Guyana"},
	{code:"HK" ,label: "Hong Kong"},
	{code:"HM" ,label: "Îles Heard-Et-MacDonald"},
	{code:"HN" ,label: "Honduras"},
	{code:"HR" ,label: "Croatie"},
	{code:"HT" ,label: "Haïti"},
	{code:"HU" ,label: "Hongrie"},
	{code:"ID" ,label: "Indonésie"},
	{code:"IE" ,label: "Irlande"},
	{code:"IL" ,label: "Israël"},
	{code:"IM" ,label: "Île De Man"},
	{code:"IN" ,label: "Inde"},
	{code:"IO" ,label: "Territoire Britannique De L'océan Indien"},
	{code:"IQ" ,label: "Irak"},
	{code:"IR" ,label: "République Islamique D'Iran"},
	{code:"IS" ,label: "Islande"},
	{code:"IT" ,label: "Italie"},
	{code:"JE" ,label: "Jersey"},
	{code:"JM" ,label: "Jamaïque"},
	{code:"JO" ,label: "Jordanie"},
	{code:"JP" ,label: "Japon"},
	{code:"KE" ,label: "Kenya"},
	{code:"KG" ,label: "Kirghizistan"},
	{code:"KH" ,label: "Cambodge"},
	{code:"KI" ,label: "Kiribati"},
	{code:"KM" ,label: "Comores"},
	{code:"KN" ,label: "Saint-Christophe-et-Niévès"},
	{code:"KP" ,label: "République Populaire Démocratique De Corée"},
	{code:"KR" ,label: "République De Corée"},
	{code:"KW" ,label: "Koweït"},
	{code:"KY" ,label: "Îles Caïmans"},
	{code:"KZ" ,label: "Kazakhstan"},
	{code:"LA" ,label: "République Démocratique Populaire Lao"},
	{code:"LB" ,label: "Liban"},
	{code:"LC" ,label: "Sainte-Lucie"},
	{code:"LI" ,label: "Liechtenstein"},
	{code:"LK" ,label: "Sri Lanka"},
	{code:"LR" ,label: "Liberia"},
	{code:"LS" ,label: "Lesotho"},
	{code:"LT" ,label: "Lituanie"},
	{code:"LU" ,label: "Luxembourg"},
	{code:"LV" ,label: "Lettonie"},
	{code:"LY" ,label: "Libye"},
	{code:"MA" ,label: "Maroc"},
	{code:"MC" ,label: "Monaco"},
	{code:"MD" ,label: "République De Moldavie"},
	{code:"ME" ,label: "Monténégro"},
	{code:"MF" ,label: "Saint-Martin (Partie Française)"},
	{code:"MG" ,label: "Madagascar"},
	{code:"MH" ,label: "Îles Marshall"},
	{code:"MK" ,label: "Macédoine"},
	{code:"ML" ,label: "Mali"},
	{code:"MM" ,label: "Birmanie"},
	{code:"MN" ,label: "Mongolie"},
	{code:"MO" ,label: "Macao"},
	{code:"MP" ,label: "Îles Mariannes Du Nord"},
	{code:"MQ" ,label: "Martinique"},
	{code:"MR" ,label: "Mauritanie"},
	{code:"MS" ,label: "Montserrat"},
	{code:"MT" ,label: "Malte"},
	{code:"MU" ,label: "Maurice"},
	{code:"MV" ,label: "Maldives"},
	{code:"MW" ,label: "Malawi"},
	{code:"MX" ,label: "Mexique"},
	{code:"MY" ,label: "Malaisie"},
	{code:"MZ" ,label: "Mozambique"},
	{code:"NA" ,label: "Namibie"},
	{code:"NC" ,label: "Nouvelle-Calédonie"},
	{code:"NE" ,label: "Niger"},
	{code:"NF" ,label: "Île Norfolk"},
	{code:"NG" ,label: "Nigéria"},
	{code:"NI" ,label: "Nicaragua"},
	{code:"NL" ,label: "Pays-Bas"},
	{code:"NO" ,label: "Norvège"},
	{code:"NP" ,label: "Népal"},
	{code:"NR" ,label: "Nauru"},
	{code:"NU" ,label: "Niue"},
	{code:"NZ" ,label: "Nouvelle-Zélande"},
	{code:"OM" ,label: "Oman"},
	{code:"PA" ,label: "Panama"},
	{code:"PE" ,label: "Pérou"},
	{code:"PF" ,label: "Polynésie Française"},
	{code:"PG" ,label: "Papouasie-Nouvelle-Guinée"},
	{code:"PH" ,label: "Philippines"},
	{code:"PK" ,label: "Pakistan"},
	{code:"PL" ,label: "Pologne"},
	{code:"PM" ,label: "Saint-Pierre-Et-Miquelon"},
	{code:"PN" ,label: "Pitcairn"},
	{code:"PR" ,label: "Porto Rico"},
	{code:"PS" ,label: "Territoires Palestiniens Occupés"},
	{code:"PT" ,label: "Portugal"},
	{code:"PW" ,label: "Palaos"},
	{code:"PY" ,label: "Paraguay"},
	{code:"QA" ,label: "Qatar"},
	{code:"RE" ,label: "Réunion"},
	{code:"RO" ,label: "Roumanie"},
	{code:"RS" ,label: "Serbie"},
	{code:"RU" ,label: "Fédération De Russie"},
	{code:"RW" ,label: "Rwanda"},
	{code:"SA" ,label: "Arabie Saoudite"},
	{code:"SB" ,label: "Îles Salomon"},
	{code:"SC" ,label: "Seychelles"},
	{code:"SD" ,label: "Soudan"},
	{code:"SE" ,label: "Suède"},
	{code:"SG" ,label: "Singapour"},
	{code:"SH" ,label: "Sainte-Hélène"},
	{code:"SI" ,label: "Slovénie"},
	{code:"SJ" ,label: "Svalbard Et Jan Mayen"},
	{code:"SK" ,label: "Slovaquie"},
	{code:"SL" ,label: "Sierra Leone"},
	{code:"SM" ,label: "Saint-Marin"},
	{code:"SN" ,label: "Sénégal"},
	{code:"SO" ,label: "Somalie"},
	{code:"SR" ,label: "Suriname"},
	{code:"SS" ,label: "Soudan Du Sud"},
	{code:"ST" ,label: "Sao Tomé-Et-Principe"},
	{code:"SV" ,label: "République Du Salvador"},
	{code:"SX" ,label: "Saint-Martin (Partie Néerlandaise)"},
	{code:"SY" ,label: "République Arabe Syrienne"},
	{code:"SZ" ,label: "Swaziland"},
	{code:"TC" ,label: "Îles Turks-Et-Caïcos"},
	{code:"TD" ,label: "Tchad"},
	{code:"TF" ,label: "Terres Australes Françaises"},
	{code:"TG" ,label: "Togo"},
	{code:"TH" ,label: "Thaïlande"},
	{code:"TJ" ,label: "Tadjikistan"},
	{code:"TK" ,label: "Tokelau"},
	{code:"TL" ,label: "Timor-Leste"},
	{code:"TM" ,label: "Turkménistan"},
	{code:"TN" ,label: "Tunisie"},
	{code:"TO" ,label: "Tonga"},
	{code:"TR" ,label: "Turquie"},
	{code:"TT" ,label: "Trinité-Et-Tobago"},
	{code:"TV" ,label: "Tuvalu"},
	{code:"TW" ,label: "Taïwan"},
	{code:"TZ" ,label: "République-Unie De Tanzanie"},
	{code:"UA" ,label: "Ukraine"},
	{code:"UG" ,label: "Ouganda"},
	{code:"UM" ,label: "Îles Mineures Éloignées Des États-Unis"},
	{code:"US" ,label: "États-Unis"},
	{code:"UY" ,label: "Uruguay"},
	{code:"UZ" ,label: "Ouzbékistan"},
	{code:"VA" ,label: "Saint-Siège (État De La Cité Du Vatican)"},
	{code:"VC" ,label: "Saint-Vincent-Et-Les Grenadines"},
	{code:"VE" ,label: "Venezuela"},
	{code:"VG" ,label: "Îles Vierges Britanniques"},
	{code:"VI" ,label: "Îles Vierges Des États-Unis"},
	{code:"VN" ,label: "Viet Nam"},
	{code:"VU" ,label: "Vanuatu"},
	{code:"WF" ,label: "Wallis Et Futuna"},
	{code:"WS" ,label: "Samoa"},
	{code:"YE" ,label: "Yémen"},
	{code:"YT" ,label: "Mayotte"},
	{code:"ZA" ,label: "Afrique Du Sud"},
	{code:"ZM" ,label: "Zambie"},
	{code:"ZW" ,label: "Zimbabwe"}]