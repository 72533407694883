import React, { useRef, useState } from "react";
import ReactPhoneInput from "react-phone-input-2";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button, TextField, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import 'react-phone-input-2/lib/style.css'
import FormHelperText from '@mui/material/FormHelperText';
import '../styles/Form.css'
import CountrySelect from "./CountrySelect";

function getSteps() {
  return [
    "Etape 1",
    "Etape 2",
  ];
}


const LinaerStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const salutation = useRef(null);
  const last_name = useRef(null);
  const first_name = useRef(null);
  const email = useRef(null);
  const [phone, setPhone] = useState("");
  const [formation, setFormation] = useState("");
  const [niveaux, setNiveux] = useState("");
  const lycee = useRef(null);
  const ville_du_lycee = useRef(null);
  const adressePostal = useRef(null);
  const codePostal = useRef(null);
  const ville = useRef(null);
  const pays = useRef(null);
  const phone_parent = useRef(null);
  const email_parent = useRef(null);
  const [errors, setErrors] = useState({})
  const steps = getSteps();
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <div>
              <FormControl>
                <FormLabel size="small" id="demo-row-radio-buttons-group-label">Civilité :</FormLabel>
                <RadioGroup
                  row
                  defaultValue="Mlle"
                  name="salutation"
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  
                >
                  <FormControlLabel value="Mlle" control={<Radio inputRef={salutation} />} label="Mlle" />
                  <FormControlLabel value="M" control={<Radio inputRef={salutation} />} label="M" />
                </RadioGroup>
              </FormControl>
              <Row>
                <Col md={6} className="mt-2">
                  <TextField error={errors.last_name ? 'true' : ''} helperText={errors.last_name} size="small" inputRef={last_name} fullWidth id="outlined-basic" label="Nom de famille" variant="outlined" />
                </Col>
                <Col md={6} className="mt-2">
                  <TextField error={errors.first_name ? 'true' : ''} helperText={errors.first_name} size="small" inputRef={first_name} fullWidth name="first_name" id="outlined-basic" label="Prénom" variant="outlined" />
                </Col>
                <Col md={12} className="mt-2">
                  <TextField error={errors.email ? 'true' : ''} helperText={errors.email} size="small" inputRef={email} fullWidth name="email" id="outlined-basic" label="E-mail" variant="outlined" />
                </Col>
                <Col md={12} className="mt-2">
                  <FormHelperText>Téléphone  </FormHelperText>
                  <ReactPhoneInput onChange={(e) => {
                    setPhone(e)
                    console.log(e)
                  }}
                    error={errors.phone}
                    className='bg-input' value="Téléphone portable" country={"ma"} regions={['africa', 'europe']} />
                  <FormHelperText className="text-danger">{errors.phone}</FormHelperText>
                </Col>
                <Col md={12} className="mt-2">
                  <FormControl fullWidth error={errors.formation ? 'true' : ''} size="small" className="formation-souhaitee"  >
                    <InputLabel id="formation-souhaitee">Formation souhaitée</InputLabel>
                    <Select
                      labelId="formation-souhaitee"
                      id="demo-simple-select"
                      label="Formation souhaitée"
                      onChange={(e) => { setFormation(e.target.value) }}
                    >
                      <MenuItem value="Médecine">Médecine</MenuItem>
                      <MenuItem value="Médecine Dentaire">Médecine Dentaire</MenuItem>
                      <MenuItem value="Pharmacie">Pharmacie</MenuItem>
                    </Select>
                    <FormHelperText>{errors.formation}</FormHelperText>
                  </FormControl>

                </Col>
                <Col md={12} className="mt-2">
                  <FormControl fullWidth size="small" error={errors.niveaux ? 'true' : ''} className="niveau-souhaitee" >
                    <InputLabel id="niveaux-souhaitee">Niveau souhaitée</InputLabel>
                    <Select
                      labelId="niveaux-souhaitee"
                      label="Niveau souhaitée"
                      error={errors.niveaux ? 'true' : ''}
                      onChange={(e) => { setNiveux(e.target.value) }}
                    >
                      <MenuItem value="1ère Année">1ère Année</MenuItem>
                      <MenuItem value="2éme Année">2ème année</MenuItem>
                      <MenuItem value="3ème année">3ème année</MenuItem>
                      <MenuItem value="4ème année">4ème année</MenuItem>
                    </Select>
                    <FormHelperText>{errors.niveaux}</FormHelperText>

                  </FormControl>
                </Col>
                <Col md={12} className="mt-2">
                  <TextField error={errors.lycee ? 'true' : ''} helperText={errors.lycee} size="small" inputRef={lycee} fullWidth name="lycee" id="outlined-basic" label="Nom du lycée" variant="outlined" />
                </Col>
                <Col md={12} className="mt-2">
                  <TextField error={errors.ville_du_lycee ? 'true' : ''} helperText={errors.ville_du_lycee} size="small" inputRef={ville_du_lycee} fullWidth name="lycee" id="outlined-basic" label="Ville du lycée" variant="outlined" />
                </Col>
              </Row>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <Row>

              <input type="text" value={salutation.current.value} name="Civilité" className="d-none" />
              <input type="text" value={last_name.current.value} name="last_name" className="d-none" />
              <input type="text" value={first_name.current.value} name="first_name" className="d-none" />
              <input type="text" value={email.current.value} name="email" className="d-none" />
              <input type="text" value={phone} name="Téléphone" className="d-none" />
              <input type="text" value={formation} name="formation souhaitee" className="d-none" />
              <input type="text" value={niveaux} name="niveaux souhaitee" className="d-none" />
              <input type="text" value={lycee.current.value} name="lycee" className="d-none" />
              <input type="text" value={ville_du_lycee.current.value} name="ville du lycee" className="d-none" />
              <Col md={12}>
                <TextField size="small" inputRef={adressePostal} fullWidth name="Adresse postale" id="outlined-basic" label="Adresse postale" variant="outlined" />
              </Col>
              <Col md={12} className="mt-2">
                <TextField size="small" inputRef={codePostal} fullWidth name="code_postal" id="outlined-basic" label="Code postal" variant="outlined" />
              </Col>
              <Col md={12} className="mt-2">
                <TextField size="small" inputRef={ville} fullWidth name="ville" id="outlined-basic" label="Ville" variant="outlined" />
              </Col>
              <Col md={12} className="mt-2">
                <CountrySelect pays={pays} />
              </Col>
              <Col md={12} className="mt-2">
                <FormHelperText>Tél tuteur  </FormHelperText>
                <ReactPhoneInput className='bg-input' name="teléphone_parent" inputRef={phone_parent} country={"ma"} placeholder="Téléphone portable tuteur" regions={['africa', 'europe']} />
              </Col>
              <Col md={12} className="mt-2">
                <TextField size="small" fullWidth name="email_parent" inputRef={email_parent} id="outlined-basic" label="E-mail parent" variant="outlined" />
              </Col>
            </Row>
          </>
        );
      default:
        return "unknown step";
    }
  }
  const handleNext = (e) => {
    e.preventDefault()
    setErrors({
      salutation: "",
      last_name: "",
      first_name: "",
      email: "",
      phone: "",
      formation: "",
      niveaux: "",
      lycee: "",
      ville_du_lycee: ""
    })
    console.log(salutation)
    if (!salutation.current.value) {
      return setErrors({
        salutation: 'Civilité est Obligatoire'
      })
    }
    if (!last_name.current.value) {
      return setErrors({
        last_name: 'Nom est Obligatoire'
      })
    }
    if (!first_name.current.value) {
      return setErrors({
        first_name: 'Prénom est Obligatoire'
      })
    }
    if (!email.current.value) {
      return setErrors({
        email: 'Email est Obligatoire'
      })
    }
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email.current.value)) {
      return setErrors({
        email: 'Format incorrect'
      })
    }
    if (phone === "") {
      return setErrors({
        phone: 'Téléphone est Obligatoire'
      })
    }
    if (phone.substring(0, 3) === '212' && phone.length !== 12) {
      return setErrors({
        phone: 'Format incorrect'
      })
    }
    if (formation === '') {
      return setErrors({
        formation: 'Formation est Obligatoire'
      })
    }
    if (niveaux === '') {
      return setErrors({
        niveaux: 'Niveaux est Obligatoire'
      })
    }
    if (!lycee.current.value) {
      return setErrors({
        lycee: 'Nom du lycée est Obligatoire'
      })
    }
    if (!ville_du_lycee.current.value) {
      return setErrors({
        ville_du_lycee: 'Ville du lycée est Obligatoire'
      })
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const HandleSubmit = (e) => {
    e.preventDefault()
    setActiveStep(activeStep + 1);
    console.log('Submit ')
  }
  return (
    <div className='form'>
      {activeStep === steps.length ? (
        <Typography variant="h6" align="center">
          Votre candidature a bien été enregistrée, nous allons vous contacter dans les plus brefs délais.
        </Typography>
      ) : (
        <>
          <h3 className='fw-bold text-center'>
            Je Candidate
          </h3>
          <form onSubmit={HandleSubmit}>
            {getStepContent(activeStep)}
            <div className="mx-auto text-center mt-3">
              {activeStep !== 0 ?
                <Button
                  type="button"
                  className="w-50 text-start"
                  onClick={handleBack}
                >
                  Retour
                </Button>
                :
                ""
              }
              {activeStep === steps.length - 1 ?
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="w-50 text-end btnSubmit"
                >
                  Envoyer
                </Button>
                :
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="w-50 text-end btnSubmitSuivant rounded-0"
                  onClick={handleNext}
                >
                  Suivant
                </Button>
              }
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default LinaerStepper;