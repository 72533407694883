import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../images/LOGO.png'
function Header() {
  return (
    <Navbar>
      <Container className="justify-content-center">
        <Navbar.Brand href="/" className="text-center">
              <img src={Logo} width='250px' className='mx-auto' alt="Logo" />
          </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Header;