import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "../styles/Banner.css"
import Part3 from './Part3'
const Banner = () => {
  return (
    <Container fluid>
        <Row>
            <Col md={6} className="Content">
                <h2 className='title'>
                    FORMULAIRE DE CANDIDATURE 
                </h2>
                <div>
                    <ul className='text-white fs-5'>
                        <li className='fw-bold'>
                            MÉDECINE 
                        </li>
                        <li className='fw-bold'>
                            MÉDECINE DENTAIRE 
                        </li>
                        <li className='fw-bold'>
                            PHARMACIE
                        </li>
                    </ul>
                </div>
                <p className="fw-bold text-white fs-5">
                    Ecole de Médecine St Christopher Iba Mar Diop - Université El Hadji Ibrahima Niasse
                   <br /> DAKAR - SENEGAL
                </p>
                <p className="text-danger fw-bold fs-6">
                    Rentrée Septembre 2023
                </p>
                <p className="fw-bold text-white">
                              </Col>
            <Col md={5}>
                <Part3 />
            </Col>
        </Row>
    </Container>
  )
}

export default Banner